<template>
        <v-row
        justify="center"
        >
            <v-col
            cols="12"
            md="4"
            >
                <v-card>
                    <v-card-text>
                        <v-img
                          :src="require('../assets/logo.png')"
                          contain
                          height="90"
                          style="margin:60px 0px"
                        />
                        <v-form>
                            <v-text-field
                            v-model="$v.user.username.$model"
                            :error-messages="usernameErrors"
                            label="Usuario"
                            required
                            @keypress.enter="login"
                            ></v-text-field>
                            <v-text-field
                            v-model="$v.user.password.$model"
                            :error-messages="passwordErrors"
                            label="Contraseña"
                            type="password"
                            @keypress.enter="login"
                            >
                            </v-text-field>

                            <div
                            class="d-flex justify-center"
                            >
                                <div>
                                    <v-btn
                                    color="primary"
                                    @click="submit"
                                    :loading="loginLoad"
                                    id="btnLogin"
                                    >
                                        Acceder
                                    </v-btn>
                                </div>
                            </div>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                        color="blue accent-4"
                        plain
                        exact :to="{name: 'ForgotPassword'}"
                        >
                            ¿Has olvidado tu Contraseña?
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <ErrorHandler
            :notify="error"
            />
        </v-row>

</template>

<script>
    import {mapMutations,mapActions} from "vuex"
    import { validationMixin } from 'vuelidate'
    import { required,email } from 'vuelidate/lib/validators'
    import ErrorHandler from '@/components/ErrorHandler.vue'

    export default {
        mixins: [validationMixin],
        data(){
            return {
                user: {
                    username: '',
                    password: ''
                },
                loginLoad: false,
                error: {error: ''}
            }
        },
        computed: {
            usernameErrors () {
                const errors = []
                if (!this.$v.user.username.$dirty) return errors
                !this.$v.user.username.required && errors.push('Campo requerido')
                !this.$v.user.username.email && errors.push('Ingrese un correo electrónico valido')
                return errors
            },
            passwordErrors () {
                const errors = []
                if (!this.$v.user.password.$dirty) return errors
                !this.$v.user.password.required && errors.push('Campo requerido')
                return errors
            },
        },
        validations: {
            user: {
                username: { required,email },
                password: { required }
            }
        },
        methods: {
            ...mapMutations(['setLayout','setNotify']),
            ...mapActions(['setSchoolCycle']),
            submit(){
                this.$v.$touch()

                if(!this.$v.$invalid){
                    this.login()
                }
            },
            login(){
                this.loginLoad = true
                this.$store.dispatch('retrieveToken', this.user)
                .then((response) => {
                    this.loginLoad = false
                    let user = response.data.user
                    this.setSchoolCycle(this.moment().format("Y"))

                    if(user.first_login_at == null){
                        this.$router.push({ name: 'FirstLogin', params: { userid: user.id } })
                    }else{
                        if(user.name_roles.includes('Padre')){
                            this.$router.push({ name: 'ParentHome' })
                        }else{
                            this.$router.push({ name: 'Home' })
                        }
                    }
                })
                .catch((error) => {
                    this.loginLoad = false

                    this.error = {  'show':true,
                                    'color':'warning',
                                    'error': error,
                                    'time':5000
                                }
                })
            },
        },
        created(){
            this.setLayout('login-layout')
        },
        components: {
            ErrorHandler
        }
    }
</script>
